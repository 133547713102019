import React, { useState, useEffect, useCallback } from "react";
import audio from "../../config/audio";
import experience from "../../config/experience";
import { useLanguage } from "./language";

export const COOKIE_ID = 'musei-di-tutti-location';

const ExperienceContext = React.createContext(null);

export default function Experience({ children }) {

  const { language } = useLanguage();

  const [location, setLocation] = useState(localStorage.getItem(COOKIE_ID));
  const [spots, setSpots] = useState([]);
  const [active, setActive] = useState();
  const [volume, setVolume] = useState(1);

  const reset = () => {
    localStorage.removeItem(COOKIE_ID);
    window.location = "/";
  };

  const startSpot = useCallback((code) => {
    const spot = spots.find(e => e.code.toUpperCase() === code.toUpperCase());
    if (spot) {
      setActive(spot);
    } else {
      return "code_not_found";
    }
  },[spots]);

  const endSpot = () => setActive();

  useEffect(() => {
    if (location && language) {
      const current = experience.find(e => e.id === location);
      if (current && current.spots) {
        const audios = audio(language);
        const spots = current.spots.map(e => ({
          ...e,
          audio: audios.find(audio => audio.id === e.audio)
        }));
        setSpots(spots);
      }
    }
    if (location && location.length > 0) localStorage.setItem(COOKIE_ID,location);
    else localStorage.removeItem(COOKIE_ID);
  }, [location, language]);

  return (
    <ExperienceContext.Provider
      value={{
        spots,
        active,
        location,
        volume,
        setVolume,
        setLocation,
        startSpot,
        endSpot,
        reset,
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}

export const useExperience = () => React.useContext(ExperienceContext);
