const it = {
  lang: "Italiano",
  select_lang: "Scegli la lingua",
  day: "Giorno",
  night: "Notte",
  confirm: "Conferma",
  continue: "Continua",
  yes_continue: "Si, continua",
  start: "Inizia",
  back: "Indietro",
  wait: "Attendi...",
  headset: "Indossa le cuffie in dotazione per sentire l'audio correttamente",
  raise_volume: "Aumenta il volume dello smartphone",
  sound: "Stai sentendo l'audio correttamente?",
  location: "Nessun museo selezionato",
  areyouready: "Sei pronto per iniziare la visita?",
  welcome: "BENVENUTO",
  beforestart: "Inserisci il codice del contenuto che vuoi ascoltare e premi CONFERMA.",
  code: "ASCOLTA",
  info: "CREDITS",
  insert_code: "Inserisci un codice",
  code_not_found: "Codice non trovato",
  loading_title: "Ancora un momento",
  loading: "Attendi il caricamento dei contenuti...",
  close: "Chiudi",
  mobile_only: "Questa App è  progettata per dispositivi mobili",
  listening_to: "STAI ASCOLTANDO",

  admin_title: "Pannello amministrazione",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_hard: "Reset",

  credits_title: "MEMORIE SONORE",
  credits_subtitle: "Suoni e immagini tra passato e presente",
  credits_desc_1: "È un progetto di",
  credits_desc_2: "Con il contributo di",
  credits_desc_3: "Concept e sound design",
  
  credits_fiesole_title: "Area archeologica di Fiesole",
  credits_primoconti_title: "Museo Primo Conti",
  credits_palazzovecchio_title: "Museo di Palazzo Vecchio",
  credits_innocenti_title: "Museo degli Innocenti",

  credits_fiesole: "Ideazione::Silvia Borsotti, Irene Dei, Chiara Ferrari;;Testi::Irene Dei, Chiara Ferrari;;Immagini::Comune di Fiesole;;Voce narrante::Nicola Pecci;;Voce mimo latino::Annalisa Matarazzo;;Traduzione in inglese::Florinda Nesticò;;Traduzione in latino::Vincenzo Rotondi",
  credits_primoconti: "Ideazione e testi::Maria Chiara Berni;;Testi narrati::tratti da P. Conti, La Gola del Merlo, memorie provocate da G.C.Millet, Firenze, Sansoni Editore, 1983;;Immagini::Fondazione Primo Conti;;Voce narrante::Annalisa Matarazzo;;Voce Primo Conti::Guglielmo Favilla, Angelo Esposito;;Traduzione in inglese::Florinda Nesticò",
  credits_palazzovecchio: "Ideazione e testi:: Valentina Zucchi, Giaele Monaci;;Immagini:: Musei Civici Fiorentini, Comune di Firenze;;Voce narrante:: Guglielmo Favilla, Angelo Esposito;;Voce citazione:: Nicola Pecci;;Voce Isabel:: Elena De Carolis;;Voce Duchessa:: Sara Fallani;;Traduzione in inglese:: Florinda Nesticò",
  credits_innocenti: "Ideazione e testi:: Arabella Natalini, Fiorenza Redi;;Immagini:: Istituto degli Innocenti;;Voce narrante italiano/inglese:: Elena De Carolis, Sara Fallani;;Traduzione in inglese:: Florinda Nesticò",
  // faq:: [
  //   {
  //     q: "Problemi con la connessione?",
  //     a: "Se hai problemi con la connessione prova a...",
  //   },
  //   { q: "Altra FAQ", a: "Se hai problemi con la connessione prova a..." },
  // ],
};

export default it;
