import React, { useState } from "react";
import { Box, Dialog, Typography, Button } from "@material-ui/core";
import Layout from "./Layout";
import Logo from "./Logo";
import String from "./String";
import test_sound from "../../assets/audio/test_sound.mp3";
import { useTheme } from "../contexts/theme";
import { useExperience } from "../contexts/experience";
import AudioTestIcon from '@material-ui/icons/VolumeUp';

export default function AdminControls({ show, onClose }) {
  const { theme } = useTheme();
  const { reset } = useExperience();
  const [audioTest, setAudioTest] = useState(false);

  const handleClose = () => {
    onClose();
    setAudioTest(false);
  }

  return (
    <Dialog fullScreen open={show} className={"lock " + theme}>
      <Layout
        top={
          <Box mt={7} textAlign="center">
            <Logo />
          </Box>
        }
      >
        <Box textAlign="center" style={{ position: 'relative' }}>
          <Typography variant="title" mb={2}>
            <String s="admin_title" />
          </Typography>
          <Box mb={2}>
            <Button color="secondary" variant="contained" fullWidth onClick={reset}>
              <String s="admin_reset_hard" />
            </Button>
          </Box>
          <Box mb={8}>
            <Button color="secondary" variant="contained" fullWidth onClick={() => setAudioTest(test => !test)}
            endIcon={audioTest ? <AudioTestIcon /> : <></>}
            >
              <String s="admin_test" />
            </Button>
            {audioTest &&<audio src={test_sound} autoPlay loop style={{ display: "none" }} />}
          </Box>
          <Box>
            <Button color="secondary" variant="contained" fullWidth onClick={handleClose}>
              <String s="admin_close" />
            </Button>
          </Box>
        </Box>
      </Layout>
    </Dialog>
  );
}