import it from "./it";
import en from "./en";
import { default_language } from "../languages";

const languages = { it, en };

export default function audio(language = default_language) {
  const selectedLangAudios = languages[language];
  return [
    { id: "fiesole_1", format: "mp3", ...selectedLangAudios.fiesole[0] },
    { id: "fiesole_2", format: "mp3", ...selectedLangAudios.fiesole[1] },
    { id: "fiesole_3", format: "mp3", ...selectedLangAudios.fiesole[2] },
    { id: "fiesole_4", format: "mp3", ...selectedLangAudios.fiesole[3] },
    { id: "fiesole_5", format: "mp3", ...selectedLangAudios.fiesole[4] },
    { id: "fiesole_6", format: "mp3", ...selectedLangAudios.fiesole[5] },

    { id: "primoConti_1", format: "mp3", ...selectedLangAudios.primoConti[0] },
    { id: "primoConti_2", format: "mp3", ...selectedLangAudios.primoConti[1] },
    { id: "primoConti_3", format: "mp3", ...selectedLangAudios.primoConti[2] },
    { id: "primoConti_4", format: "mp3", ...selectedLangAudios.primoConti[3] },
    { id: "primoConti_5", format: "mp3", ...selectedLangAudios.primoConti[4] },

    { id: "innocenti_1", format: "mp3", ...selectedLangAudios.innocenti[0] },
    { id: "innocenti_2", format: "mp3", ...selectedLangAudios.innocenti[1] },
    { id: "innocenti_3", format: "mp3", ...selectedLangAudios.innocenti[2] },
    { id: "innocenti_4", format: "mp3", ...selectedLangAudios.innocenti[3] },
    { id: "innocenti_5", format: "mp3", ...selectedLangAudios.innocenti[4] },
    { id: "innocenti_6", format: "mp3", ...selectedLangAudios.innocenti[5] },

    { id: "palazzoVecchio_1", format: "mp3", ...selectedLangAudios.palazzoVecchio[0] },
    { id: "palazzoVecchio_2", format: "mp3", ...selectedLangAudios.palazzoVecchio[1] },
    { id: "palazzoVecchio_3", format: "mp3", ...selectedLangAudios.palazzoVecchio[2] },
    { id: "palazzoVecchio_4", format: "mp3", ...selectedLangAudios.palazzoVecchio[3] },
    { id: "palazzoVecchio_5", format: "mp3", ...selectedLangAudios.palazzoVecchio[4] },
    { id: "palazzoVecchio_6", format: "mp3", ...selectedLangAudios.palazzoVecchio[5] },
  ];
}
