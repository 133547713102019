import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Slider,
  Stack,
  Typography,
} from "@material-ui/core";
import PreviousIcon from "@material-ui/icons/FastRewind";
import NextIcon from "@material-ui/icons/FastForward";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import LoadingIcon from "@material-ui/icons/HourglassBottomOutlined";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import String from "./String";
import { useExperience } from "../contexts/experience";
import { Howl } from "howler";

export default function Player() {
  const { active, endSpot, volume, setVolume } = useExperience();

  const [audio, setAudio] = useState();
  const [mute, setMute] = useState(false);
  const [progress, setProgress] = useState(0);

  const duration = audio ? audio.duration() : 0;
  const perc = audio ? (progress / duration) * 100 : 0;
  const elapsed = audio && formatTimer(progress);
  const actualVolume = mute ? 0 : volume;
  audio && audio.volume(actualVolume);
  // const countdown = player && formatTimer(duration - progress);

  const skip = (s) => {
    const _s = progress + s;
    if (0 < _s < duration) audio && audio.seek(_s);
  };

  const handleSliderChange = (e) => {
    setMute(false);
    setVolume(e.target.value);
  }

  useEffect(() => {
    if (active && active.audio && !audio) {
      const howl = new Howl({
        src: [active.audio.url],
        html5: true,
        autoplay: true,
        loop: false,
        volume: 1,
        onload: () => setAudio(howl),
        onstop: endSpot,
        onend: endSpot
      });
    }
  }, [active, endSpot, audio]);

  useEffect(() => {
    const seek = setInterval(() => {
      const p = audio && audio.seek();
      setProgress(p);
    }, 100);
    return () => {
      clearInterval(seek);
      if (audio) {
        audio.playing() && audio.stop();
        audio.unload();
      }
    }
  }, [audio])

  return (
    <Stack width="100%" textAlign="center" alignItems="center" justifyContent="space-between">
      <Stack p={3} pb="10vh" spacing={4}>
        <Typography variant="subtitle">
          <String s="listening_to" />
        </Typography>
        <Typography variant="title">
          {active.audio.label}
        </Typography>
      </Stack>

      <Stack width="70vw" spacing={2}>
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <IconButton color="secondary" onClick={() => skip(-5)}>
              <PreviousIcon style={{ fontSize: "10vw" }} />
            </IconButton>
            <Typography fontSize={"3vw"}>
              5 s
            </Typography>
          </Box>
          <Box>
            <IconButton color="secondary"
              onClick={() => audio && audio.playing() ? audio.pause() : audio.play()}
              style={btnStyle}
            >
              <Box style={countDownStyle}>
                {audio ? (audio.playing() ? <PauseIcon style={{ fontSize: "10vw" }} /> : <PlayIcon style={{ fontSize: "10vw" }} />)
                : <LoadingIcon style={{ fontSize: "10vw" }} />}
              </Box>
              <CircularProgress
                color="secondary"
                variant="determinate"
                value={Math.floor(perc)}
                style={progressStyle}
                thickness={2.5}
              />
            </IconButton>
            <Typography fontSize={"3vw"} mt={1}>
              {audio ? `${elapsed.mm}:${elapsed.ss}` : "..."}
            </Typography>
          </Box>
          <Box>
            <IconButton color="secondary" onClick={() => skip(5)}>
              <NextIcon style={{ fontSize: "10vw" }} />
            </IconButton>
            <Typography fontSize={"3vw"}>
              5 s
            </Typography>
          </Box>
        </Stack>
        <Stack width="100%" pr={3} pb={2} direction="row" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <IconButton style={{marginRight: 10}} color="secondary" onClick={() => setMute(mute => !mute)}>
            {actualVolume === 0 ? <VolumeOffIcon style={{fontSize: "10vw"}}/>
            : actualVolume < .3 ? <VolumeMuteIcon style={{fontSize: "10vw"}}/>
            : actualVolume < .7 ? <VolumeDownIcon style={{fontSize: "10vw"}}/>
            : <VolumeUpIcon style={{fontSize: "10vw"}}/>
            }
          </IconButton>
          <Slider
            size="small"
            color="secondary"
            min={0} max={1}
            value={actualVolume}
            step={0.01}
            onChange={handleSliderChange}
          />
        </Stack>
        <Button fullWidth color="secondary" variant="contained" onClick={endSpot}>
          <String s="close" />
        </Button>
      </Stack>
    </Stack>
  );
}

const formatTimer = (t) => {
  if (t > 0) {
    const o = new Date(0);
    const p = new Date(t * 1000);
    const array = new Date(p.getTime() - o.getTime())
      .toISOString()
      .split('T')[1]
      .split('Z')[0]
      .split(':');
    return {
      mm: array[1],
      ss: array[2].split('.')[0]
    };
  } else {
    return { mm: "00", ss: "00" };
  }
};

const btnStyle = {
  position: "relative",
  border: "2px solid",
  height: "22vw",
  width: "22vw",
};
const countDownStyle = {
  position: "absolute",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
};
const progressStyle = { position: "absolute", height: "107%", width: "107%" };
