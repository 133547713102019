import fiesole1Url from '../../assets/audio/it/AA Fiesole ITA/AA Fiesole ITA 1.mp3';
import fiesole2Url from '../../assets/audio/it/AA Fiesole ITA/AA Fiesole ITA 2.mp3';
import fiesole3Url from '../../assets/audio/it/AA Fiesole ITA/AA Fiesole ITA 3.mp3';
import fiesole4Url from '../../assets/audio/it/AA Fiesole ITA/AA Fiesole ITA 4.mp3';
import fiesole5Url from '../../assets/audio/it/AA Fiesole ITA/AA Fiesole ITA 5.mp3';
import fiesole6Url from '../../assets/audio/it/AA Fiesole ITA/AA Fiesole ITA 6.mp3';

import primoConti1Url from '../../assets/audio/it/Primo Conti ITA/Primo Conti ITA 1.mp3';
import primoConti2Url from '../../assets/audio/it/Primo Conti ITA/Primo Conti ITA 2.mp3';
import primoConti3Url from '../../assets/audio/it/Primo Conti ITA/Primo Conti ITA 3.mp3';
import primoConti4Url from '../../assets/audio/it/Primo Conti ITA/Primo Conti ITA 4.mp3';
import primoConti5Url from '../../assets/audio/it/Primo Conti ITA/Primo Conti ITA 5.mp3';

import innocenti1Url from '../../assets/audio/it/Museo Innocenti ITA/Museo Innocenti ITA 1.mp3';
import innocenti2Url from '../../assets/audio/it/Museo Innocenti ITA/Museo Innocenti ITA 2.mp3';
import innocenti3Url from '../../assets/audio/it/Museo Innocenti ITA/Museo Innocenti ITA 3.mp3';
import innocenti4Url from '../../assets/audio/it/Museo Innocenti ITA/Museo Innocenti ITA 4.mp3';
import innocenti5Url from '../../assets/audio/it/Museo Innocenti ITA/Museo Innocenti ITA 5.mp3';
import innocenti6Url from '../../assets/audio/it/Museo Innocenti ITA/Museo Innocenti ITA 6.mp3';

import palazzoVecchio1Url from '../../assets/audio/it/Palazzo Vecchio ITA/Palazzo Vecchio ITA 1.mp3';
import palazzoVecchio2Url from '../../assets/audio/it/Palazzo Vecchio ITA/Palazzo Vecchio ITA 2.mp3';
import palazzoVecchio3Url from '../../assets/audio/it/Palazzo Vecchio ITA/Palazzo Vecchio ITA 3.mp3';
import palazzoVecchio4Url from '../../assets/audio/it/Palazzo Vecchio ITA/Palazzo Vecchio ITA 4.mp3';
import palazzoVecchio5Url from '../../assets/audio/it/Palazzo Vecchio ITA/Palazzo Vecchio ITA 5.mp3';
import palazzoVecchio6Url from '../../assets/audio/it/Palazzo Vecchio ITA/Palazzo Vecchio ITA 6.mp3';

const it = {
  fiesole: [
    { url: fiesole1Url, label: 'Terrazza - Introduzione' }, 
    { url: fiesole2Url, label: 'Teatro - Cavea' },
    { url: fiesole3Url, label: 'Teatro - Proscaenium' },
    { url: fiesole4Url, label: 'Terme - Calidarium' },
    { url: fiesole5Url, label: 'Terme - Portico e piscine' },
    { url: fiesole6Url, label: 'Tempio' },
  ],

  primoConti: [
    { url: primoConti1Url, label: 'Villa Le Coste' },
    { url: primoConti2Url, label: 'La cappella' },
    { url: primoConti3Url, label: 'Sala consultazione' },
    { url: primoConti4Url, label: 'Sala degli autoritratti' },
    { url: primoConti5Url, label: 'Sala dei ritratti' },
  ],

  innocenti: [
    { url: innocenti1Url, label: 'Il loggiato - La finestra ferrata' },
    { url: innocenti2Url, label: 'Il cortile delle donne' },
    { url: innocenti3Url, label: 'L\'archivio storico' },
    { url: innocenti4Url, label: 'La galleria d\'arte' },
    { url: innocenti5Url, label: 'La sala del Ghirlandaio' },
    { url: innocenti6Url, label: 'Il coretto delle donne' },
  ],

  palazzoVecchio: [
    { url: palazzoVecchio1Url, label: 'Piazza della Signoria' },
    { url: palazzoVecchio2Url, label: 'Corte di Michelozzo' },
    { url: palazzoVecchio3Url, label: 'Salone dei Cinquecento' },
    { url: palazzoVecchio4Url, label: 'Appartamenti della Duchessa Eleonora' },
    { url: palazzoVecchio5Url, label: 'Sala delle carte geografiche' },
    { url: palazzoVecchio6Url, label: 'Torre di Arnolfo' },
  ]
};

export default it;