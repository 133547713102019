const palette = {
  white: "#FFFFFF",
  white_rgb: "255, 255, 255",
  dark_white: "#EFEFEF",
  red: "#940000",
  green: "#32fd43",
  grey: "#A5A093",
  light_grey: "#F2EFEB",
  light_grey_rgb: "242, 239, 235",
  dark_grey: "#191A1C",
  dark_grey_rgb: "25, 26, 28",
  blue: "#0071bb",
  blue_rgb: "0, 113, 187",
  dark_blue: "#00385d",
  dark_blue_rgb: "0, 56, 93"
};

export default palette;
