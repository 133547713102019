import React, { useState } from "react";
import languages, { default_language } from "../../config/languages";
import alarm_it from "../../assets/audio/it/lock_alert.mp3";
import alarm_en from "../../assets/audio/en/lock_alert.mp3";

const ALARM_SOUNDS = {
  it: alarm_it,
  en: alarm_en,
};

const LanguageContext = React.createContext(null);

export default function Language({ children }) {
  const [language, setLanguage] = useState(default_language);
  const availableLanguages = Object.keys(languages);
  const content = (key, l) => languages[l || language][key];
  const alarmSound = ALARM_SOUNDS[language];

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, availableLanguages, content, alarmSound }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => React.useContext(LanguageContext);