import palette from "./palette";

const styles = {
  palette: {
    primary: {
      main: palette.dark_grey,
      contrastText: palette.white
    },
    secondary: {
      main: palette.blue,
      contrastText: palette.white
    },
    selected: {
      main: palette.dark_blue,
      contrastText: palette.white
    },
  },
  typography: {
    fontFamily: "Messina Sans",
    title: {
      fontWeight: "bold",
      fontSize: "7vw",
      lineHeight: "8vw",
      '@media (max-height:800px)': {
        fontSize: '6.5vw',
        lineHeight: "7vw",
      },
    },
    pretitle: {
      fontSize: "3.6vw",
      fontWeight: "bold",
    },
    text: {
      fontSize: "3.6vw",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: "h2",
          pretitle: "h5",
          subtitle: "h4",
          text: "p",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { textAlign: "left" },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: { fontSize: "6vw" },
        input: { width: "100px" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "3.6vw",
          padding: "3vw 15vw",
          '@media (max-height:800px)': {
            fontSize: '3.5vw',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "3.6vw",
          fontWeight: "bold",
          padding: "3vw 0px",
        },
      },
    },
  },
  shadows: (function () {
    return Array.from(Array(25).keys()).map(() => "none");
  })(),
};

const common = { styles };

export default common;
