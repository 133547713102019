import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Link,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Layout from "../components/Layout";
import String from "../components/String";
import Header from "../components/Header";
import NoSleep from "nosleep.js";
import { useLanguage } from "../contexts/language";
import { useExperience } from "../contexts/experience";
import Player from "../components/Player";

import logoContiDay from "../../assets/img/conti_day.png";
import logoContiNight from "../../assets/img/conti_night.png";
import logoCristoforoDay from "../../assets/img/cristoforo_day.png";
import logoCristoforoNight from "../../assets/img/cristoforo_night.png";
import logoDiocesiDay from "../../assets/img/diocesi_day.png";
import logoDiocesiNight from "../../assets/img/diocesi_night.png";
import logoFiesoleDay from "../../assets/img/fiesole_day.png";
import logoFiesoleNight from "../../assets/img/fiesole_night.png";
import logoFirenzeDay from "../../assets/img/firenze_day.png";
import logoFirenzeNight from "../../assets/img/firenze_night.png";
import logoIdiDay from "../../assets/img/idi_day.png";
import logoIdiNight from "../../assets/img/idi_night.png";
import logoMuseDay from "../../assets/img/muse_day.png";
import logoMuseNight from "../../assets/img/muse_night.png";

import logoToscanaDay from "../../assets/img/toscana_day.png";
import logoToscanaNight from "../../assets/img/toscana_night.png";
import logoMezzoforteDay from "../../assets/img/mezzoforte_day.png";
import logoMezzoforteNight from "../../assets/img/mezzoforte_night.png";
import logoMuseiDiTuttiDay from "../../assets/svg/logo_day.svg";
import logoMuseiDiTuttiNight from "../../assets/svg/logo_day.svg";

import { useTheme } from "../contexts/theme";

const noSleep = new NoSleep();

const CODE_LENGHT = 1;

export default function Tour() {

  useEffect(() => {
    document.body.classList.add('no-pull-refresh');
    noSleep.enable();

    return () => {
      document.body.classList.remove('no-pull-refresh');
      noSleep.disable();
    }
  }, []);

  return (
    <Layout
      full
      top={<Header />}
    >
      <TabsContainer />
    </Layout>
  );
}

function TabsContainer() {
  const [tab, setTab] = useState("code");
  return (
    <Layout
      full
      top={
        <AppBar position="static" color="transparent">
          <Tabs
            value={tab}
            onChange={(e, t) => setTab(t)}
            variant="fullWidth"
            textColor="secondary"
            style={{
              boxShadow: "0 3px 5px #191A1C11"
            }}
          >
            <Tab value={"code"} label={<Typography 
            variant="pretitle" color={tab === "code" ? "secondary" : undefined}>
              <String s="code" />
            </Typography>}/>
            <Tab value={"info"} label={<Typography 
            variant="pretitle" color={tab === "info" ? "secondary" : undefined}>
              <String s="info" />
            </Typography>}/>
          </Tabs>
        </AppBar>
      }
    >
      <CodeTab visible={tab === "code"}/>
      <InfoTab visible={tab === "info"}/>
    </Layout>
  );
}

// // eslint-disable-next-line
// function Overlay({ loaded, started, onStart }) {

//   return (
//     <Backdrop style={{ position: "absolute", zIndex: 9 }} open={true}>
//       <Layout justify="center">
//         <Box textAlign="center" width={"70vw"}>
//           { !started ? 
//             <Stack spacing={3}>
//               <Typography variant="title">
//                 <String s="areyouready" />
//               </Typography>
//               <Typography variant="text">
//                 <String s="beforestart" />
//               </Typography>
//               <Button
//                 fullWidth
//                 color="secondary"
//                 variant="contained"
//                 onClick={onStart}
//                 disabled={started}
//               >
//                 <String s="start" />
//               </Button>
//             </Stack> : <Stack alignItems="center" spacing={3}>
//               <Typography variant="title">
//                 <String s="loading_title" />
//               </Typography>
//               <Typography variant="text">
//                 <String s="loading" />
//               </Typography>
//               <IconButton style={btnStyle}>
//                 <Box style={countDownStyle}>
//                   <Typography color="primary" variant="text">{`${Math.floor(loaded * 100)}%`}</Typography>
//                 </Box>
//                 <CircularProgress
//                   color="secondary"
//                   variant="determinate"
//                   value={Math.floor(loaded * 100)}
//                   style={progressStyle}
//                   thickness={2.5}
//                 />
//               </IconButton>
//             </Stack>
//           }
//         </Box>
//       </Layout>
//     </Backdrop>
//   );
// }

// const countDownStyle = {
//   position: "absolute",
//   height: "100%",
//   width: "100%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-around",
//   alignItems: "center",
// };
// const progressStyle = { position: "absolute", height: "107%", width: "107%" };
// const btnStyle = {
//   position: "relative",
//   border: "2px solid",
//   height: "22vw",
//   width: "22vw",
//   borderRadius: "50%"
// };

function CodeTab({visible}) {
  const { startSpot, active } = useExperience();
  const { language, availableLanguages, setLanguage } = useLanguage();

  const [code, setCode] = useState("");
  const [error, setError] = useState();

  const handleCodeChange = (e) => {
    setError();
    if (e.target.value.length <= CODE_LENGHT)
      setCode(e.target.value);
  }
  const handleCodeFocus = () => {
    setError();
    setCode("");
  }
  const handleConfirm = () => {
    setError();
    const error = startSpot(code);
    if (error) setError(error);
    else setCode("");
  }
  const handleKeyUp = (e) => {
    if (e.key === "Enter" && code.length >= CODE_LENGHT) handleConfirm();
  }

  return (
    <Layout hidden={!visible} full justify="center"> 
      {active ? <Player/> :
      <>
        <Stack justifyContent="space-between" width="70vw" height="70%" style={{ textAlign: "center" }}>
          <Stack pt={3} spacing={3}>
            <Box style={{ textAlign: "center" }}>
              <Typography variant="subtitlex"><String s="insert_code" /></Typography>
            </Box>
            <Box style={{ textAlign: "center" }}>
              <TextField color="secondary" variant="standard" onChange={handleCodeChange} onFocus={handleCodeFocus} onKeyUp={handleKeyUp} value={code}
              inputProps={{ type: "number", style: {fontSize: 40, textAlign: "center", textTransform: "uppercase"} }}
              />
              {error ? <Typography><String s={error} /></Typography>
              : <Typography style={{opacity: 0}}>Not visible</Typography>}
            </Box>
            <Box style={{ textAlign: "center" }}>
              <Button 
              fullWidth
              disabled={code.length < CODE_LENGHT} 
              color="secondary" 
              variant="contained" 
              onClick={handleConfirm}>
                <String s="confirm" />
              </Button>
            </Box>
          </Stack>
          <Stack pb={3} spacing={2}>
            <Typography variant="text"><String s="select_lang" /></Typography>
            <FormControl variant="outlined" color="secondary" fullWidth>
              <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              >
                {availableLanguages.map((l) => (
                  <MenuItem value={l} key={l}>
                    <String s="lang" l={l} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Typography style={{position: "absolute", bottom: 25}} color="secondary" variant="text">Powered by mezzoforte</Typography>
      </>}
    </Layout>
  );
}

function CreditLine({e}) {
  const title = e.split("::").length > 1 ? e.split("::")[0] : undefined;
  const text = e.split("::").length > 1 ? e.split("::")[1] : e;
  return <Typography variant="text" style={{marginBottom: 5}}>
    {title && <strong>{`${title}: `}</strong>}
    {text}
  </Typography>
}

function InfoTab({visible}) {
  const { content } = useLanguage();
  const { theme } = useTheme();

  return (
    <Layout hidden={!visible} justify="start" full>

      <Box width={"85vw"} style={{ paddingTop: 30 }}>
        <Typography variant="title" style={{marginBottom: 15}}><String s="credits_title"/></Typography>
        <Typography variant="pretitle" style={{marginBottom: 30}}><String s="credits_subtitle"/></Typography>

        <Stack style={{marginBottom: 15}} direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="text"><String s="credits_desc_1"/></Typography>
            <Link href="http://www.firenzefiesolemusei.net/"><Typography color="secondary" variant="text">www.firenzefiesolemusei.net</Typography></Link>
          </Stack>
          <Box width="35%" textAlign="center">
            <img alt="logo" height="50px" src={theme === "day" ? logoMuseiDiTuttiDay : logoMuseiDiTuttiNight}/>
          </Box>
        </Stack>

        <Stack style={{marginBottom: 15}} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="text"><String s="credits_desc_2"/></Typography>
          <Box width="35%" textAlign="center">
            <img alt="logo" height="70px" src={theme === "day" ? logoToscanaDay : logoToscanaNight}/>
          </Box>
        </Stack>
        
        <Stack style={{marginBottom: 30}} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="text"><String s="credits_desc_3"/></Typography>
          <Box width="35%" textAlign="center">
            <img alt="logo" width="60px" src={theme === "day" ? logoMezzoforteDay : logoMezzoforteNight}/>
          </Box>
        </Stack>
      </Box>

      {theme === "day" ? <Grid container spacing={4} px={4} justifyContent="center" alignItems="center">
        <Grid item xs={4}><img alt="logo" style={{maxWidth: "100%", maxHeight: "70px"}} src={logoContiDay}/></Grid>
        <Grid textAlign="center" item xs={4}><img alt="logo" style={{maxWidth: "100%", maxHeight: "70px"}} src={logoDiocesiDay}/></Grid>
        <Grid textAlign="right" item xs={4}><img alt="logo" style={{maxWidth: "100%", maxHeight: "70px"}} src={logoFiesoleDay}/></Grid>
        {/* <Grid item xs={3}><img alt="logo" width="100%" src={logoToscanaDay}/></Grid> */}
        <Grid item xs={4}><img alt="logo" width="100%" src={logoFirenzeDay}/></Grid>
        <Grid item xs={4}><img alt="logo" width="100%" src={logoIdiDay}/></Grid>
        <Grid item xs={4}><img alt="logo" width="100%" src={logoMuseDay}/></Grid>
        <Grid item xs={6}><img alt="logo" width="100%" src={logoCristoforoDay}/></Grid>
        {/* <Grid item xs={4}><img alt="logo" width="100%" src={logoMezzoforteDay}/></Grid> */}
      </Grid> 
      : <Grid container spacing={4} px={4} justifyContent="center" alignItems="center">
        <Grid item xs={4}><img alt="logo" style={{maxWidth: "100%", maxHeight: "70px"}} src={logoContiNight}/></Grid>
        <Grid textAlign="center" item xs={4}><img alt="logo" style={{maxWidth: "100%", maxHeight: "70px"}} src={logoDiocesiNight}/></Grid>
        <Grid textAlign="right" item xs={4}><img alt="logo" style={{maxWidth: "100%", maxHeight: "70px"}} src={logoFiesoleNight}/></Grid>
        {/* <Grid item xs={3}><img alt="logo" width="100%" src={logoToscanaNight}/></Grid> */}
        <Grid item xs={4}><img alt="logo" width="100%" src={logoFirenzeNight}/></Grid>
        <Grid item xs={4}><img alt="logo" width="100%" src={logoIdiNight}/></Grid>
        <Grid item xs={4}><img alt="logo" width="100%" src={logoMuseNight}/></Grid>
        <Grid item xs={6}><img alt="logo" width="100%" src={logoCristoforoNight}/></Grid>
        {/* <Grid item xs={4}><img alt="logo" width="100%" src={logoMezzoforteNight}/></Grid> */}
      </Grid>}

      <Box width={"85vw"} style={{ paddingTop: 50 }}>
        <Typography variant="title" style={{marginBottom: 15}}><String s="credits_fiesole_title"/></Typography>
        {content("credits_fiesole").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
      </Box>
      <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
        <Typography variant="title" style={{marginBottom: 15}}><String s="credits_primoconti_title"/></Typography>
        {content("credits_primoconti").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
      </Box>
      <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
        <Typography variant="title" style={{marginBottom: 15}}><String s="credits_palazzovecchio_title"/></Typography>
        {content("credits_palazzovecchio").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
      </Box>
      <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
        <Typography variant="title" style={{marginBottom: 15}}><String s="credits_innocenti_title"/></Typography>
        {content("credits_innocenti").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
      </Box>

      <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
        {content("faq") && content("faq").map((f, fi) => (
          <Accordion key={fi}>
            <AccordionSummary expandIcon={<ExpandMore />} id={"faq_" + fi}>
              <Typography>{f.q}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">{f.a}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Layout>
  );
}

// old credits tab layout
// function InfoTab({visible}) {
//   const { content } = useLanguage();
//   const { theme } = useTheme();

//   return (
//     <Layout hidden={!visible} justify="start" full>

//       <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
//         <Typography variant="title" style={{marginBottom: 15}}><String s="credits_title"/></Typography>
//         <Typography variant="pretitle" style={{marginBottom: 15}}><String s="credits_subtitle"/></Typography>
//         <Typography variant="text" style={{marginBottom: 15}}><String s="credits_desc"/></Typography>
//       </Box>

//       {theme === "day" ? <Grid container spacing={4} padding={4} justifyContent="center" alignItems="center">
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoContiDay}></img></Grid>
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoDiocesiDay}></img></Grid>
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoFiesoleDay}></img></Grid>
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoToscanaDay}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoFirenzeDay}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoIdiDay}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoMuseDay}></img></Grid>
//         <Grid item xs={8}><img alt="logo" width="100%" src={logoCristoforoDay}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoMezzoforteDay}></img></Grid>
//       </Grid> 
//       : <Grid container spacing={4} padding={4} justifyContent="center" alignItems="center">
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoContiNight}></img></Grid>
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoDiocesiNight}></img></Grid>
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoFiesoleNight}></img></Grid>
//         <Grid item xs={3}><img alt="logo" width="100%" src={logoToscanaNight}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoFirenzeNight}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoIdiNight}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoMuseNight}></img></Grid>
//         <Grid item xs={8}><img alt="logo" width="100%" src={logoCristoforoNight}></img></Grid>
//         <Grid item xs={4}><img alt="logo" width="100%" src={logoMezzoforteNight}></img></Grid>
//       </Grid>}

//       <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
//         <Typography variant="title" style={{marginBottom: 15}}><String s="credits_fiesole_title"/></Typography>
//         {content("credits_fiesole").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
//       </Box>
//       <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
//         <Typography variant="title" style={{marginBottom: 15}}><String s="credits_primoconti_title"/></Typography>
//         {content("credits_primoconti").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
//       </Box>
//       <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
//         <Typography variant="title" style={{marginBottom: 15}}><String s="credits_palazzovecchio_title"/></Typography>
//         {content("credits_palazzovecchio").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
//       </Box>
//       <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
//         <Typography variant="title" style={{marginBottom: 15}}><String s="credits_innocenti_title"/></Typography>
//         {content("credits_innocenti").split(";;").map((e, idx) => <CreditLine key={idx} e={e}/>)}
//       </Box>

//       <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
//         {content("faq") && content("faq").map((f, fi) => (
//           <Accordion key={fi}>
//             <AccordionSummary expandIcon={<ExpandMore />} id={"faq_" + fi}>
//               <Typography>{f.q}</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Typography color="textSecondary">{f.a}</Typography>
//             </AccordionDetails>
//           </Accordion>
//         ))}
//       </Box>
//     </Layout>
//   );
// }
