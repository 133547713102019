const en = {
  lang: "English",
  select_lang: "Choose your language",
  day: "Day",
  night: "Night",
  confirm: "Confirm",
  continue: "Continue",
  yes_continue: "Yes, continue",
  start: "Start",
  back: "Back",
  wait: "Plase wait...",
  headset: "Please use the provided headset for a better experience",
  raise_volume: "Turn up your device's volume",
  sound: "Can you hear the sound correctly?",
  location: "No museum selected",
  areyouready: "Are you ready to start the visit?",
  welcome: "WELCOME",
  beforestart: "Write the code of the content you want to listen and press CONFIRM",
  code: "LISTEN",
  info: "CREDITS",
  insert_code: "Insert code",
  code_not_found: "Code not found",
  loading_title: "Almost there",
  loading: "Please wait while content is loading...",
  close: "Close",
  mobile_only: "This App was designed for mobile devices.",
  listening_to: "LISTENING TO",

  admin_title: "Administration panel",
  admin_close: "Close",
  admin_test: "Test audio",
  admin_reset_hard: "Reset",

  credits_title: "SOUND MEMORIES",
  credits_subtitle: "Sounds and images between past and present",
  credits_desc_1: "Project by",
  credits_desc_2: "With the contribution of",
  credits_desc_3: "Concept and sound design",

  credits_fiesole_title: "Archeological Area of Fiesole",
  credits_primoconti_title: "Primo Conti Museum",
  credits_palazzovecchio_title: "Palazzo Vecchio Museum",
  credits_innocenti_title: "Museum of Innocenti",

  credits_fiesole: "Idea and project::Silvia Borsotti, Irene Dei, Chiara Ferrari;;Texts::Irene Dei, Chiara Ferrari;;Images::Comune di Fiesole;;Narrator::Nicola Pecci;;Latin speaker::Annalisa Matarazzo;;English translation::Florinda Nesticò;;Latin translation::Vincenzo Rotondi",
  credits_primoconti: "Idea and project::Maria Chiara Berni;;Narrated texts taken from P. Conti, La Gola del Merlo, memorie provocate da G.C.Millet, Firenze, Sansoni Editore, 1983;;Images::Fondazione Primo Conti;;Narrator::Annalisa Matarazzo;;Primo Conti voice::Guglielmo Favilla, Angelo Esposito;;English translation::Florinda Nesticò",
  credits_palazzovecchio: "Idea and project::Valentina Zucchi, Giaele Monaci;;Images::Musei Civici Fiorentini, Comune di Firenze;;Narrators::Guglielmo Favilla, Angelo Esposito;;Quotations voice::Nicola Pecci;;Isabel voice::Elena De Carolis;;Duchess voice::Sara Fallani;;English translation::Florinda Nesticò",
  credits_innocenti: "Idea and project::Arabella Natalini, Fiorenza Redi;;Images::Istituto degli Innocenti;;Narrator::Elena De Carolis, Sara Fallani;;English translation::Florinda Nesticò",
 
  // faq::[
  //   {
  //     q: "Connection problems?",
  //     a: "If you are experiencing connection problems try to...",
  //   },
  //   {
  //     q: "Other FAQ",
  //     a: "If you are experiencing connection problems try to...",
  //   },
  // ]
};

export default en;
