const experience = [
  {
    id: "fiesole",
    label: "Area Archeologica di Fiesole",
    spots: [
      { id: "fiesole_1", code: "1", audio: "fiesole_1" },
      { id: "fiesole_2", code: "2", audio: "fiesole_5" },
      { id: "fiesole_3", code: "3", audio: "fiesole_4" },
      { id: "fiesole_4", code: "4", audio: "fiesole_6" },
      { id: "fiesole_5", code: "5", audio: "fiesole_3" },
      { id: "fiesole_6", code: "6", audio: "fiesole_2" },
    ]
  },
  {
    id: "primoConti",
    label: "Museo Primo Conti",
    spots: [
      { id: "primoConti_1", code: "1", audio: "primoConti_1" },
      { id: "primoConti_2", code: "2", audio: "primoConti_4" },
      { id: "primoConti_3", code: "3", audio: "primoConti_5" },
      { id: "primoConti_4", code: "4", audio: "primoConti_3" },
      { id: "primoConti_5", code: "5", audio: "primoConti_2" },
    ]
  },
  {
    id: "palazzoVecchio",
    label: "Museo di Palazzo Vecchio",
    spots: [
      { id: "palazzoVecchio_1", code: "1", audio: "palazzoVecchio_1" },
      { id: "palazzoVecchio_2", code: "2", audio: "palazzoVecchio_2" },
      { id: "palazzoVecchio_3", code: "3", audio: "palazzoVecchio_3" },
      { id: "palazzoVecchio_4", code: "4", audio: "palazzoVecchio_4" },
      { id: "palazzoVecchio_5", code: "5", audio: "palazzoVecchio_5" },
      { id: "palazzoVecchio_6", code: "6", audio: "palazzoVecchio_6" },
    ]
  },
  {
    id: "innocenti",
    label: "Museo degli Innocenti",
    spots: [
      { id: "innocenti_1", code: "1", audio: "innocenti_1" },
      { id: "innocenti_2", code: "2", audio: "innocenti_2" },
      { id: "innocenti_3", code: "3", audio: "innocenti_3" },
      { id: "innocenti_4", code: "4", audio: "innocenti_4" },
      { id: "innocenti_5", code: "5", audio: "innocenti_5" },
      { id: "innocenti_6", code: "6", audio: "innocenti_6" }
    ]
  }
];

export default experience;
