import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./components/Routes";

import Theme from "./contexts/theme";
import Language from "./contexts/language";
import Experience from "./contexts/experience";

import Boarding from "./views/Boarding";
import Tour from "./views/Tour";

import "./css/App.css";
import 'react-toastify/dist/ReactToastify.css';

export default function Ui() {
  return (
    <Contexts>
      <Routes test="root" views={views} />
    </Contexts>
  );
}

function Contexts({ children }) {
  return (
    <Language>
      <Experience>
        <Theme>
          <Router>{children}</Router>
        </Theme>
      </Experience>
    </Language>
  );
}

const views = [
  {
    name: "Boarding",
    path: ["/boarding"],
    component: Boarding,
  },
  {
    name: "Tour",
    path: "/tour",
    component: Tour,
  },
];
