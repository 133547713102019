import fiesole1Url from '../../assets/audio/en/AA Fiesole ENG/AA Fiesole ENG 1.mp3';
import fiesole2Url from '../../assets/audio/en/AA Fiesole ENG/AA Fiesole ENG 2.mp3';
import fiesole3Url from '../../assets/audio/en/AA Fiesole ENG/AA Fiesole ENG 3.mp3';
import fiesole4Url from '../../assets/audio/en/AA Fiesole ENG/AA Fiesole ENG 4.mp3';
import fiesole5Url from '../../assets/audio/en/AA Fiesole ENG/AA Fiesole ENG 5.mp3';
import fiesole6Url from '../../assets/audio/en/AA Fiesole ENG/AA Fiesole ENG 6.mp3';

import primoConti1Url from '../../assets/audio/en/Primo Conti ENG/Primo Conti ENG 1.mp3';
import primoConti2Url from '../../assets/audio/en/Primo Conti ENG/Primo Conti ENG 2.mp3';
import primoConti3Url from '../../assets/audio/en/Primo Conti ENG/Primo Conti ENG 3.mp3';
import primoConti4Url from '../../assets/audio/en/Primo Conti ENG/Primo Conti ENG 4.mp3';
import primoConti5Url from '../../assets/audio/en/Primo Conti ENG/Primo Conti ENG 5.mp3';

import innocenti1Url from '../../assets/audio/en/Museo Innocenti ENG/Museo Innocenti ENG 1.mp3';
import innocenti2Url from '../../assets/audio/en/Museo Innocenti ENG/Museo Innocenti ENG 2.mp3';
import innocenti3Url from '../../assets/audio/en/Museo Innocenti ENG/Museo Innocenti ENG 3.mp3';
import innocenti4Url from '../../assets/audio/en/Museo Innocenti ENG/Museo Innocenti ENG 4.mp3';
import innocenti5Url from '../../assets/audio/en/Museo Innocenti ENG/Museo Innocenti ENG 5.mp3';
import innocenti6Url from '../../assets/audio/en/Museo Innocenti ENG/Museo Innocenti ENG 6.mp3';

import palazzoVecchio1Url from '../../assets/audio/en/Palazzo Vecchio ENG/Palazzo Vecchio ENG 1.mp3';
import palazzoVecchio2Url from '../../assets/audio/en/Palazzo Vecchio ENG/Palazzo Vecchio ENG 2.mp3';
import palazzoVecchio3Url from '../../assets/audio/en/Palazzo Vecchio ENG/Palazzo Vecchio ENG 3.mp3';
import palazzoVecchio4Url from '../../assets/audio/en/Palazzo Vecchio ENG/Palazzo Vecchio ENG 4.mp3';
import palazzoVecchio5Url from '../../assets/audio/en/Palazzo Vecchio ENG/Palazzo Vecchio ENG 5.mp3';
import palazzoVecchio6Url from '../../assets/audio/en/Palazzo Vecchio ENG/Palazzo Vecchio ENG 6.mp3';

const en = {
  fiesole: [
    { url: fiesole1Url, label: 'Terrace - Introduction' }, 
    { url: fiesole2Url, label: 'Theater - Cavea' },
    { url: fiesole3Url, label: 'Theater - Proscaenium' },
    { url: fiesole4Url, label: 'Thermal baths - Calidarium' },
    { url: fiesole5Url, label: 'Thermal baths - Portico and pools' },
    { url: fiesole6Url, label: 'Temple' },
  ],

  primoConti: [
    { url: primoConti1Url, label: 'Villa Le Coste' },
    { url: primoConti2Url, label: 'The chapel' },
    { url: primoConti3Url, label: 'Consultation room' },
    { url: primoConti4Url, label: 'Hall of self-portraits' },
    { url: primoConti5Url, label: 'Hall of portraits' },
  ],

  innocenti: [
    { url: innocenti1Url, label: 'The loggia - barred window' },
    { url: innocenti2Url, label: 'The women\'s courtyard' },
    { url: innocenti3Url, label: 'The historical archive' },
    { url: innocenti4Url, label: 'The art gallery' },
    { url: innocenti5Url, label: 'Ghirlandaio\'s hall' },
    { url: innocenti6Url, label: 'The women\'s choir' },
  ],

  palazzoVecchio: [
    { url: palazzoVecchio1Url, label: 'Piazza della Signoria' },
    { url: palazzoVecchio2Url, label: 'Michelozzo\'s courtyard' },
    { url: palazzoVecchio3Url, label: 'Hall of Five-hundreds' },
    { url: palazzoVecchio4Url, label: 'Apartments of Duchess Eleonora' },
    { url: palazzoVecchio5Url, label: 'Maps room' },
    { url: palazzoVecchio6Url, label: 'Arnolfo\'s tower' },
  ]
};

export default en;