import palette from "./palette";
import common from "./common.js";

// day theme
const styles = {
  ...common.styles,
  palette: {
    ...common.styles.palette,
    mode: "light",
    background: {
      default: palette.white,
      rgb: palette.white_rgb,
      contrast: palette.dark_grey,
    },
  },
  components: {
    ...common.styles.components,
    MuiBackdrop: {
      styleOverrides: {
        root: { background: `${palette.white}BB` },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { background: palette.white },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: { background: palette.blue },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: { background: palette.blue }
      }
    }
  },
};

const image_dir = "./day";

const day = { styles, image_dir };

export default day;
