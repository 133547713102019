import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";

export default function Footer({ children }) {
  return (
    <AppBar position="static" color="transparent">
      <Toolbar
        style={{ justifyContent: "space-around", background: "transparent" }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
}
